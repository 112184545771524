// Khởi tạo + cấu hình Axios instances tương ứng với các endpoint API
import Router from 'next/router'
import { INVOICE_ENDPOINT, NOTIFICATION_ENDPOINT, PMS_ENDPOINT, V4_ENDPOINT, CRM_API } from 'src/configs/API'
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/nextjs'
import moment from 'moment';

const { default: axios } = require('axios')
// const router = useRouter()

//base: v4-api.247post
const v4Api = axios.create({
	baseURL: V4_ENDPOINT,
	headers: {
		'Cache-Control': 's-maxage=1, stale-while-revalidate=59', 
	},
})
v4Api.interceptors.request.use(
	function (config) {
		// custom headers here
		return config
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

v4Api.interceptors.response.use(
	function (response) {
		if (response.status === 401) {
			localStorage.clear();
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		return response.data
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

//pms: 247tech.xyz
const pmsApi = axios.create({
	baseURL: PMS_ENDPOINT,
})

pmsApi.interceptors.request.use(
	function (config) {
		//add customed headers here
		const userData =
			(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) || {}
		
		config.headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			ClientID: userData.clientID,
			Token: userData.token,
		}

		return config
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		Sentry.captureException(error)
		return Promise.reject(error);
	}
)

pmsApi.interceptors.response.use(
	function (response) {
		const userData =
			(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) || {}
		if(userData && userData.expireDate){
			if ((moment(userData.expireDate) <= moment())) {
				localStorage.clear()
				window.location.href = window.location.origin + '/dang-nhap';
			}
		}
		else{
			if (response.status === 401) {
				localStorage.clear()
				Router.push(window.location.origin + '/dang-nhap')
				return
			}
		}
		return response.data
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

const invoiceApi = axios.create({
	baseURL: INVOICE_ENDPOINT,
})

invoiceApi.interceptors.request.use(
	function (config) {
		// custom headers here
		return config
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

invoiceApi.interceptors.response.use(
	function (response) {

		if (response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		return response.data
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

const notifApi = axios.create({
	baseURL: NOTIFICATION_ENDPOINT,
})

notifApi.interceptors.request.use(
	function (config) {
		// custom headers here
		config.headers = {
			ApiKey: '247express',
			ApiSecretKey: '247express',
			
		}
		return config
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

notifApi.interceptors.response.use(
	function (response) {

		if (response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		return response.data
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

//crm: 
//https://crmv2-api.247express.vn/api/
const crmApi = axios.create({
	baseURL: CRM_API,
	headers: {
		'Cache-Control': 's-maxage=1, stale-while-revalidate=59',
	},
})
crmApi.interceptors.request.use(
	function (config) {
		// custom headers here
		return config
	},
	function (error) {
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)

crmApi.interceptors.response.use(
	function (response) {
		if (response.status === 401) {
			localStorage.clear();
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		return response.data
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.clear()
			Router.push(window.location.origin + '/dang-nhap')
			return
		}
		Sentry.captureException(error)
		return Promise.reject(error)
	}
)


const request = {
	v4Api,
	pmsApi,
	invoiceApi,
	notifApi,
	crmApi
}
axiosRetry(v4Api, { retries: 3 });
axiosRetry(pmsApi, { retries: 3 });
axiosRetry(invoiceApi, { retries: 3 });
axiosRetry(notifApi, { retries: 3 });
axiosRetry(crmApi, { retries: 3 });

export default request
